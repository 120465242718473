<template>
  <div v-if="isDone">
    <ul>
      <li>Org Id: {{ organizationId }}</li>
      <li>UserGroup Id: {{ userGroupId }}</li>
      <li>User Id: {{ userId }}</li>
    </ul>
  </div>
  <v-row v-else dense
    ><v-col cols="12">
      <BaseFieldInput
        v-model="organization.attributes.name"
        label="Company Name"
    /></v-col>
    <v-col cols="12" md="6">
      <BaseFieldInput
        v-model="user.attributes.info.name"
        label="Contact Name"
      />
    </v-col>
    <v-col cols="12" md="6">
      <BaseFieldInput
        v-model="user.attributes.email"
        :label="$t('common.email') + '(*)'"
        persistent-hint
        autocomplete="new-password"
        type="email"
        :rules="emailRules"
    /></v-col>
    <v-col cols="12">
      <BaseTextareaInput
        v-model="organization.attributes.details"
        label="Details"
      />
    </v-col>

    <div class="my-3 text-h6">{{ $t('common.address') }}</div>

    <v-col cols="12">
      <BaseFieldInput
        v-model="organization.attributes.customAttributes.location.street"
        :label="$t('common.street')"
      />
    </v-col>

    <v-col cols="12" md="6">
      <BaseFieldInput
        v-model="organization.attributes.customAttributes.location.country"
        :label="$t('common.country')"
      />
    </v-col>

    <v-col cols="12" md="6">
      <BaseFieldInput
        v-model="organization.attributes.customAttributes.location.state"
        :label="$t('common.state')"
      />
    </v-col>
    <v-row dense>
      <v-col>
        <BaseFieldInput
          v-model="organization.attributes.customAttributes.location.city"
          :label="$t('common.city')"
        />
      </v-col>
      <v-col>
        <BaseFieldInput
          v-model="organization.attributes.customAttributes.location.zipcode"
          :label="$t('common.zipcode')"/></v-col
    ></v-row>
  </v-row>
</template>

<script>
import { ORGANIZATION } from '@/models/organization'
import { USER } from '@/models/user'
import { USER_GROUP } from '@/models/user.group'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      organization: ORGANIZATION(),
      user: USER(),
      userGroup: USER_GROUP('supplier'),
      organizationId: null,
      userGroupId: null,
      userId: null,
      isDone: false,
      emailRules: [
        v => !!v || `E-mail ${this.$t('hints.isRequired')}`,
        v =>
          /[^@]+@[^.]+\..*/.test(v) || `E-mail ${this.$t('hints.mustBeValid')}`
      ]
    }
  },
  methods: {
    ...mapActions({
      createOrganization: 'organization/create',
      createUser: 'user/create',
      createUserGroup: 'userGroup/create'
    }),
    async onSubmit() {
      // Organization
      const { location } = this.organization.attributes.customAttributes
      this.organization.attributes.reference = this.organization.attributes.name
      const { data: organization } = await this.createOrganization(
        this.organization
      )

      //  UserGroup Attributes
      this.userGroup.attributes.name = this.organization.attributes.name
      Object.assign(
        this.userGroup.attributes.customAttributes.address,
        location
      )
      this.userGroup.attributes.organizationId = organization.id
      const userGroup = await this.createUserGroup(this.userGroup)

      // User Attributes
      Object.assign(this.user.attributes.info.address, location)
      this.user.attributes.roles = ['admin']
      this.user.attributes.groupIds.push(userGroup.id)
      this.user.attributes.organizationId = organization.id
      const user = await this.createUser(this.user)

      this.organizationId = organization.id
      this.userGroupId = userGroup.id
      this.userId = user.id
      this.isDone = true
    }
  }
}
</script>
