<template>
  <v-card max-width="50%" class="mx-auto" outlined>
    <v-card-title>New Organization</v-card-title>
    <v-card-text>
      <v-form>
        <OrganizationEditForm ref="form" />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        color="grey lighten-1"
        light
        outlined
        @click="$router.go(-1)"
        >{{ $t('actions.cancel') }}</v-btn
      >
      <v-btn depressed color="primary" type="submit" @click="onSubmit">{{
        $t('actions.save')
      }}</v-btn></v-card-actions
    >
  </v-card>
</template>

<script>
import OrganizationEditForm from './components/OrganizationEditForm'

export default {
  components: {
    OrganizationEditForm
  },
  methods: {
    onSubmit() {
      this.$refs.form.onSubmit()
    }
  }
}
</script>
