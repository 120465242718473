export const ORGANIZATION = () => ({
  type: 'organization',
  attributes: {
    name: '',
    reference: '',
    cif: '',
    details: '',
    customAttributes: {
      location: {
        city: '',
        state: '',
        value: '',
        latlng: {},
        street: '',
        country: '',
        zipcode: '',
        formattedAddress: ''
      }
    },
    settings: {
      url: 'https://forestchain.chaingotech.com',
      logo: '',
      events: [],
      defaults: {
        parties: [],
        documents: []
      }
    },
    userIds: []
  }
})
